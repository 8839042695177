import React, { useCallback } from "react";
// material-ui
import { Card, Grid, Typography, Button, Chip } from "@mui/material";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import PackageApi from "apis/package.api";
import CouponApi from "apis/coupon.api";
import { updateAllPackage } from "redux/redux-slice/package.slice";
import { getServiceName } from "../../../utils/common.util";
import OrderApi from "apis/order.api";

// ===============================|| COLOR BOX ||=============================== //
// ===============================|| UI COLOR ||=============================== //

export default function Order() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const packageApi = new PackageApi();
  const orderApi = new OrderApi();

  const rows = useSelector((state) => state.package.Package);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllOrders = useCallback(async () => {
    try {
      const packages = await orderApi.getAllOrder();
      if (!packages || !packages.data.data) {
        return toast.error("no latest packages available");
      } else {
        console.log("Fetched Packages:", packages.data.data);
        dispatch(updateAllPackage(packages.data.data.reverse()));
        return;
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
      throw error;
    }
  });

  useEffect(() => {
    getAllOrders();
  }, []);

  function formatDate(date) {
    return new Date(date).toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return (
    <>
      <MainCard
        title={
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={gridSpacing}
          >
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                id="outlined-search"
                label="Search field"
                type="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        }
        content={false}
      >
        {rows ? (
          <Card>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ pl: 3 }}>S No.</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Address</TableCell>
                      {/* <TableCell>Image </TableCell> */}
                      <TableCell>Payment Status</TableCell>
                      <TableCell>Order Status</TableCell>
                      <TableCell>Order Type</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Total Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                   
                      .filter((row) =>
                        search.toLowerCase() === ""
                          ? row
                          : row.service.toLowerCase().includes(search)
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="start">{index + 1}</TableCell>

                            <TableCell align="start">
                              {formatDate(row.createdAt)}
                            </TableCell>

                            <TableCell align="start">
                            {row?.name ? row?.name : "-"}  
                            </TableCell>
                            <TableCell align="start">
                            {row?.number ? row?.number : "-"}
                            </TableCell>
                            <TableCell align="start">
                            {row?.address ? row?.address+"," : ""} {row?.city ? row?.city+"," : ""} {row?.state ? row?.state +"," : ""} {row?.pinCode ? (row?.pinCode) : ""}
                            </TableCell>
                            <TableCell align="start">
                              {row.paymentStatus === true ? "Paid" : "Unpaid"}{" "}
                              {/* Conditional rendering */}
                            </TableCell>

                            <TableCell align="start">
                              Pending
                            </TableCell>

                            <TableCell align="start">
                              {row.paymentType == "Cash" ? "Cash On Delivery" : "Online"}
                            </TableCell>
                            {/* <TableCell align="start">
                                                            {row.paymentStatus}
                                                        </TableCell> */}
                            <TableCell align="start">
                             
                              {row?.quantity ? row?.quantity : "0"}
                            </TableCell>
                            <TableCell align="start">₹ {row.price}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Card>
        ) : (
          <h6>Loading...</h6>
        )}
      </MainCard>
    </>
  );
}
